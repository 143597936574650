import {IHttpClient} from '@wix/fe-essentials-editor/src/exports/http-client/index';

export function markSiteWithCategoryPageStatus(httpClient: IHttpClient, appInstance: string, value: boolean) {
  return httpClient.post(
    '/_serverless/wixstores-categories-rollout-manager/markSiteAs',
    {
      value,
    },
    {
      headers: {Authorization: appInstance},
    }
  );
}

export function getCategoryPageStatusForSite(msid: string, httpClient: IHttpClient) {
  return httpClient.get<{result: {value: boolean}}>(
    `/_serverless/wixstores-categories-rollout-manager/siteRolloutStatus?metaSiteId=${msid}`
  );
}
